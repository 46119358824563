import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";

export const MenuDrawer = ({
  handleClickOpen,
  toggleDrawer,
  setToggleDrawer,
}: any) => {
  const renderWebServiceIcons = (index: number) => {
    switch (index) {
      case 0:
        return <CampaignOutlinedIcon />;
      case 1:
        return <CreateOutlinedIcon />;
      case 2:
        return <HelpOutlineIcon />;
      default:
        return <HelpOutlineIcon />;
    }
  };
  const renderExtensionServiceIcons = (index: number) => {
    switch (index) {
      case 0:
        return <NewReleasesOutlinedIcon />;
      case 1:
        return <AnnouncementOutlinedIcon />;
      case 2:
        return <HelpOutlineIcon />;
      default:
        return <HelpOutlineIcon />;
    }
  };
  const list = (anchor: string) => {
    const webstoreTabs = ["", "reviews", "support"];
    return (
      <Box
        sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
        role="presentation"
        onClick={() => setToggleDrawer(false)}
        onKeyDown={() => setToggleDrawer(false)}
      >
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          {["공지사항", "영어공부 자료"].map((text, index) => (
            <ListItemButton key={text} onClick={handleClickOpen}>
              <ListItem disablePadding>
                <ListItemIcon>{renderWebServiceIcons(index)}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            </ListItemButton>
          ))}
        </List>
        <Divider />
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          {/* "버그 리포트", "기능 건의", "Drafts" 향후 추가할 내역 */}
          {["업데이트 사항", "건의사항", "버그 리포트"].map((text, index) => (
            <ListItemButton
              key={text}
              onClick={() =>
                window.open(
                  `https://chrome.google.com/webstore/detail/avocadic-%EB%84%A4%EC%9D%B4%EB%B2%84-%EC%98%81%EC%96%B4%EC%82%AC%EC%A0%84-avocadi/hhnfbcfdlagghpdliknadeagpdbgcpnk/${webstoreTabs[index]}`,
                  "_blank"
                )
              }
            >
              <ListItem disablePadding>
                <ListItemIcon>
                  {renderExtensionServiceIcons(index)}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            </ListItemButton>
          ))}
        </List>
        <Divider />
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          {["v 2.1.2"].map((text, index) => (
            <ListItem key={text}>
              <ListItemText
                primary={text}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <Drawer
      anchor={"left"}
      open={toggleDrawer}
      onClose={() => setToggleDrawer(false)}
    >
      <aside>
        <nav>{list("left")}</nav>
      </aside>
    </Drawer>
  );
};
