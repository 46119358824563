import "./styles.css";

export const EmptyWordList = () => {
  return (
    <div className="container">
      <p className="default-word-history-eyes">👀</p>
      <p className="default-word-history-text">
        최근에 찾아본 단어가 이 곳에 표시됩니다.
      </p>
    </div>
  );
};
