import { useEffect, useState } from "react";
import scrollToTop from "../../common/scrollToTop";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { ScrollTopButton } from "../ScrollTopButton";
import "./styles.css";
import history from "../../common/history";
import Modal from "../Modal";

export const TemplateProvider = ({ children }: any) => {
  const [open, setOpen] = useState(false);

  scrollToTop();
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (history.action === "POP") {
        window.location.reload();
      }
    });
    return unlisten;
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="template-container">
      {/* @ts-ignore */}
      <Modal open={open} handleClose={handleClose} />
      <Header handleClickOpen={handleClickOpen} />
      <main>
        {children}
        <ScrollTopButton />
      </main>
      <Footer />
    </div>
  );
};
