import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import { MenuDrawer } from "../MenuDrawer";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import "./styles.css";
import { Link } from "react-router-dom";

export const Header = ({ handleClickOpen }: any) => {
  const [toggleDrawer, setToggleDrawer] = useState(false);

  return (
    <header>
      <MenuDrawer
        toggleDrawer={toggleDrawer}
        setToggleDrawer={setToggleDrawer}
        handleClickOpen={handleClickOpen}
      />
      <Grid container alignItems="center" flex={1}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          flex={1}
          justifyContent="start"
          alignItems="center"
          display="flex"
          flexDirection="row"
          gap="20px"
        >
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            onClick={() => setToggleDrawer(true)}
            id="web-avocadic-menu"
          >
            <MenuIcon />
          </Fab>
          {/* <SearchBar /> */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          flex={1}
          justifyContent="end"
          display="flex"
        >
          <div className="avocadic-web-header">
            <Link to="/" reloadDocument>
              <h1>AvocaDic</h1>
            </Link>
          </div>
        </Grid>
      </Grid>
    </header>
  );
};
