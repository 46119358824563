import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import "./styles.css";

export const Footer = () => {
  return (
    <footer className="footer-container">
      <nav>
        <Grid container>
          <Grid item xs={4}>
            <a href="https://chrome.google.com/webstore/detail/avocadic-%EB%84%A4%EC%9D%B4%EB%B2%84-%EC%98%81%EC%96%B4%EC%82%AC%EC%A0%84-avocadi/hhnfbcfdlagghpdliknadeagpdbgcpnk">
              Avocadic Dictionary 설치하기
            </a>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Link to="/privacy-policy">
              <p>Privacy policy</p>
            </Link>
            <Link to="/terms-conditions">
              <p>Terms & Conditoins</p>
            </Link>
          </Grid>
        </Grid>
      </nav>
    </footer>
  );
};
