import { useEffect, useState } from "react";
import { TemplateProvider } from "./components/TemplateProvider";
import { EmptyWordList } from "./components/EmptyWordList";

function App() {
  const [hasContent, setHasContent] = useState(false);
  // const [wordList, setWordList] = useState([]);

  const storeAppendedElements = () => {
    if (!hasContent) {
      if (document.querySelector("#contents-main-container")) {
        setHasContent(true);
        const wordElements = document.querySelectorAll(".link-title");
        const tmpWordList: string[] = [];
        if (wordElements.length > 0) {
          wordElements.forEach((elem) => {
            let wordName = elem.innerHTML;
            tmpWordList.push(wordName);
          });
          //@ts-ignore
          // setWordList(tmpWordList);
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("DOMNodeInserted", storeAppendedElements);
    return () => {
      document.removeEventListener("DOMNodeInserted", storeAppendedElements);
    };
  }, []);

  return (
    <TemplateProvider>
      <section id="avocadic-contents">
        {!hasContent && <EmptyWordList />}
      </section>
    </TemplateProvider>
  );
}

export default App;
