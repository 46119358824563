import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export const ScrollTopButton = () => {
  return (
    <Fab
      size="small"
      color="primary"
      aria-label="add"
      sx={{
        position: "fixed",
        right: 24,
        bottom: 20,
      }}
      onClick={() => window.scrollTo(0, 0)}
    >
      <KeyboardArrowUpIcon />
    </Fab>
  );
};
